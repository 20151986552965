@use "../abstracts/variables/colors";

@each $name,
$color in colors.$color-map {
  .#{$name} {
    color: $color;
  }

  .bg-#{$name} {
    background-color: $color;
  }
}