$color-map: (
  'white': #fff,
  gray-100: #ececec,
  gray-150: #d9d9d9,
  gray-200: #c7c7c7,
  gray-300: #a8a8a8,
  gray-400: #979797,
  gray-500: #898282,
  gray-600: #7a7a7a,
  gray-700: #2b2b2b,
  gray-800: #181818,
  gray-900: #020106,
  gray-1000: #060606,
  'black': #000,
  blue-100: #0045a9,
  blue-200: #0065b3,
);
