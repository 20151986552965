@use "../../../assets/scss/abstracts/variables/colors";

.tab {
    display: flex;

    &.border-blue {
        border-bottom: 1px solid map-get(colors.$color-map, blue-100);
    }

    & a {
        color: map-get(colors.$color-map, gray-300);
        text-decoration: none; 
        outline: none;

        &:hover, &:active {
            text-decoration: none; 
        }
    }

    & .line {
        margin: 0 24px;
    }
}