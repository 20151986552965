@use '../../../assets/scss/abstracts/variables/colors';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: map-get(colors.$color-map, blue-100);
  color: map-get(colors.$color-map, 'white');

  &.small {
    width: 140px;
    height: 140px;

    & img {
      width: 42px;
      height: 42px;
    }
  }

  &.medium {
    width: 150px;
    height: 150px;

    & img {
      width: 50px;
      height: 50px;
    }
  }
}
