@use '../../../assets/scss/abstracts/variables/colors';
@use '../../../assets/scss/abstracts/mixins/breakpoints';


.root {
  background: rgba($color: map-get(colors.$color-map, 'black'), $alpha: 0.7);
  border-radius: 100px;
  width: 100%;
  height: 70px;
  max-width: 456px;

  @include breakpoints.media-breakpoint-up(md) {
    height: 96px;
  }
}
