@use '../../../assets/scss/abstracts/variables/colors';
@use '../../../assets/scss/helper/layout';
@use '../../../assets/scss/abstracts/mixins/breakpoints' as breakpointsMixin;

.root {
  background-color: map-get(colors.$color-map, 'white');
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100vh;
  transform: translate(-50%, -50%);
  z-index: 1;
  overflow-y: auto;
  width: 100%;
  height: auto;
  max-height: 100vh;
  max-width: 100vw;

  .close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 30px 30px 0 30px;
    cursor: pointer;
  }

  @include breakpointsMixin.media-breakpoint-up(md) {
    min-height: fit-content;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
}
