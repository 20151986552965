@use '../../../assets/scss/abstracts/variables/colors';
@use '../../../assets/scss/abstracts/variables/flex';
@use '../../../assets/scss/abstracts/variables/breakpoints';
@use '../../../assets/scss/abstracts/mixins/breakpoints' as breakpointsMixin;

.root {
  background-color: map-get(colors.$color-map, 'white');
  border-bottom: 1px solid map-get(colors.$color-map, gray-100);
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  @include breakpointsMixin.media-breakpoint-up(md) {
    & {
      position: unset;
      border-bottom: 1px solid map-get(colors.$color-map, gray-400);
    }
  }
}

.nav-wrapper {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background-color: map-get(colors.$color-map, 'white');

  .logo-link {
    min-height: 64.5px;
    display: flex;
  }

  .logo-link > img {
    width: 100%;
    max-width: 120px;
    object-fit: contain;
  }

  @include breakpointsMixin.media-breakpoint-up(md) {
    .logo-link > img {
      max-width: 165px;
    }
  }

  .hamburger-menu-wrapper {
    position: absolute;
    top: 25px;
    right: 20px;

    & input {
      display: block;
      width: 40px;
      height: 32px;
      position: absolute;
      right: 0;

      cursor: pointer;

      opacity: 0; /* hide this */
      z-index: 2; /* and place it over the hamburger */

      -webkit-touch-callout: none;
    }

    & span {
      display: block;
      width: 30px;
      height: 2px;
      margin-bottom: 5px;
      position: relative;
      background: #cdcdcd;
      border-radius: 3px;
      z-index: 1;
      transform-origin: 4px 0px;
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
    }

    & span:nth-last-child(1) {
      transform-origin: 0% 0%;
    }

    & span:nth-last-child(2) {
      transform-origin: 0% 100%;
    }

    & input:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(1px, -3px);
      background: #232323;
    }

    & input:checked ~ span:nth-last-child(2) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    & input:checked ~ span:nth-last-child(1) {
      transform: rotate(-45deg) translate(0, 4px);
    }
  }
}

.ul-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  overflow: hidden;
  transition: height 0.3s;
  background-color: map-get(colors.$color-map, 'white');
  width: 100%;
  height: 0;
  font-size: 18px;

  & li {
    border-top: 1px solid map-get(colors.$color-map, gray-100);
    width: 100%;
    padding: 0;
    background-color: map-get(colors.$color-map, white);
  }
  & li > a {
    display: inline-block;
    width: 100%;
    padding: 8px;
    transition: color 0.3s ease;
  }
  & li > a:hover {
    color: map-get(colors.$color-map, blue-100);
  }
  &.active {
    height: 308px;
  }

  @include breakpointsMixin.media-breakpoint-up(md) {
    width: auto;
    height: auto;
    gap: 0;
    flex-direction: row;

    & li {
      width: auto;
      border-top: unset;
    }

    &.active {
      height: 100%;
    }
  }
}

.backdrop {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
}
