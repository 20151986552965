@use '../../assets/scss/abstracts/variables/colors';
@use '../../assets/scss/helper/layout';
@use '../../assets/scss/abstracts/mixins/breakpoints' as breakpointsMixin;

.root {
  & .case-wrapper {
    li {
      width: 100%;

      & img {
        width: 100%;
      }
    }

    @include breakpointsMixin.media-breakpoint-up(md) {
      justify-content: flex-start;
    }
  }
}
