@use "../abstracts/variables/display" as *;
@use "../abstracts/variables/breakpoints" as *;
@use "../abstracts/mixins/breakpoints" as breakpointsMixin;

.d {
  &-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @each $value in map-keys($displays) {
    &-#{$value} {
      display: $value;
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    @include breakpointsMixin.media-breakpoint-up($breakpoint) {
      @each $value in map-keys($displays) {
          &-#{$breakpoint}-#{$value} {
            display: $value;
          }
        }
    }
  }
}