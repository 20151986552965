@use '../../../assets/scss/abstracts/variables/colors';
@use '../../../assets/scss/helper/layout';
@use '../../../assets/scss/abstracts/mixins/breakpoints' as breakpointsMixin;

.root {
  .ol-wrapper {
    .number {
      width: 43px;
      height: 43px;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      color: map-get(colors.$color-map, gray-600);
      background-color: map-get(colors.$color-map, gray-150);
      border-radius: 50%;
      margin: 12px;
      cursor: pointer;
    }
    .active.number {
      color: map-get(colors.$color-map, 'white');
      background-color: map-get(colors.$color-map, blue-100);
    }
  }
}
