@use "../../assets/scss/abstracts/variables/colors";
@use "../../assets/scss/abstracts/mixins/breakpoints";

.tab {
    display: flex;

    &.border-blue {
        border-bottom: 1px solid map-get(colors.$color-map, blue-100);
    }

    & a {
        color: map-get(colors.$color-map, gray-300);
        text-decoration: none; 
        outline: none;

        &:hover, &:active {
            text-decoration: none; 
        }
    }

    & .line {
        margin: 0 24px;
    }
}

.round-wrapper {
    border-radius: 10px;
    height: 110px;
    width: 293px;
    background-color: map-get(colors.$color-map, gray-100);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    padding: 16px;
    margin: 0 auto;

    @include breakpoints.media-breakpoint-up(md) {
        height: 293px;
        border-radius: 50%;
    }

    & img {
        width: 80px;
    }

    &.round-blue {
        background-color: map-get(colors.$color-map, blue-100);
    }
}

.banner-warpper {
    & img {
        min-height: 150px;
        object-fit: cover;
    }
}

.recommend-wrapper {
    max-width: 412px;
    background-color: white;
    border-top-right-radius: 100px;
    border-top-left-radius: 100px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    padding-bottom: 36px;
    margin: 0 auto;

    @include breakpoints.media-breakpoint-up(lg) {
        height: 362px;
    }
}