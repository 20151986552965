@use "../../assets/scss/abstracts/variables/colors";
@use '../../assets/scss/abstracts/mixins/breakpoints' as breakpointsMixin;

.process-wrapper {
    .process {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.arrow-wrapper {
    margin-top: 60px;
}

.fast-network-title {
    font-size: 20px;
    @include breakpointsMixin.media-breakpoint-up(md) {
        font-size: 36px;
    }
}